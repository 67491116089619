import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { Product } from './Product';

class Products extends Component {

    render() {
        const { t } = this.props;
        const readmore = t('Readmore');
        return (
            <Container id="products">
                {
                    t('Products', { returnObjects: true }).map((t, index) => {
                        return (
                            <Product key={index} data={{ t: t, readmore: readmore, i: index }}></Product>
                        );
                    })
                }
            </Container>
        );
    }
}

export default withTranslation()(Products);


