import React, { Component } from 'react';
import { Carousel, Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import './Carousel.css';

class ControlledCarousel extends Component {  

    render() {
        const { t } = this.props;
        return (
            <Carousel id="home" slide={false} controls={false} pause={false} className="carousel-default" >
                <Carousel.Item>
                    <Container className="banner-info">
                        <h3 className="wow slideInUp">{t('Carousel.Welcome')}</h3>
                        <p className="wow slideInDown">{t('Carousel.Tohandfood')}</p>
                        <div>
                            <div className="arrows">
                                <div className="arrows-icon"></div>
                            </div>
                        </div>
                        <span>{t('Carousel.Tasty')}</span>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container className="banner-info">
                        <h3 className="wow slideInUp">{t('Carousel.Real')}</h3>
                        <p className="wow slideInDown">{t('Carousel.Handmade')}</p>
                        <div>
                            <div className="arrows">
                                <div className="arrows-icon"></div>
                            </div>
                        </div>
                        <span>{t('Carousel.Tasty')}</span>
                    </Container>
                </Carousel.Item>
                <Carousel.Item>
                    <Container className="banner-info">
                        <h3 className="wow slideInUp">{t('Carousel.Work')}</h3>
                        <p className="wow slideInDown">{t('Carousel.Rest')}</p>
                        <div className="wow">
                            <div className="arrows">
                                <div className="arrows-icon"></div>
                            </div>
                        </div>
                        <span>{t('Carousel.Tasty')}</span>
                    </Container>
                </Carousel.Item>
            </Carousel>
        );
    }
}

export default withTranslation()(ControlledCarousel);


