import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NavMenu from './NavMenuShort';
import Detail from './Detail';
import Footer from './Footer';
import Page404 from './Page404';

export class Details extends Component {   

    render() {

        const key = this.props.match.params.key;

        return (
            <div>
                <NavMenu></NavMenu>
                {key?<Detail data={{ key: key.substring(1) }}></Detail>:<Page404></Page404>}                           
                <Footer></Footer>
            </div>
        );
    }
}

export default withTranslation()(Details);