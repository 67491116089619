import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import NavMenu from './NavMenuShort';
import Page404 from './Page404';
import Footer from './Footer';

export class NotFoundPage extends Component {    

    render() {
        return (
            <div>
                <NavMenu></NavMenu>
                <Page404></Page404>
                <Footer></Footer>
            </div>
        );
    }
}

export default withTranslation()(NotFoundPage);