import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class Footer extends Component {

    render() {
        const { t } = this.props;
        return (
            <div className="footer text-center" id="footer">
                <Container>               
                    <div className="icon-font-size">
                        <i className="icon-icon icon-color-orange" ></i>
                    </div>
                    <div className="icon-font-size">
                        <i className="icon-name4 icon-color-white"></i>
                    </div>
                    <div>
                        <div className="arrows wow zoomIn">
                            <div className="arrows-icon"></div>
                        </div>
                    </div>
                    <span className="services-gd">
                        <h4>{t('Carousel.Tasty')}</h4>
                    </span>

                    <p className="wel-text icon-color-white">{t('Wherefind')}</p>
                    <ul className="social wow slideInUp">
                        <li>
                            <a href="https://t.me/Handmade_Food" target="_blank" data-title="Telegram" title="" rel="noopener noreferrer">
                                <i className="icon-paper-plane-2 icon-color-blue"></i>
                            </a>
                            <a href="https://wa.me/998901262233" target="_blank" data-title="WhatsApp" title="" rel="noopener noreferrer">
                                <i className="icon-whatsapp icon-color-green"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://yandex.ru/profile/218804584611" target="_blank" data-title="Yandex" title="" rel="noopener noreferrer">
                                <i className="icon-yandex icon-color-red"></i>
                            </a>
                        </li>
                        {/* <li>
                            <a href="https://yandex.ru/profile/218804584611" target="_blank" data-title="Facebook" title="" rel="noopener noreferrer">
                                <i className="icon-facebook-1 icon-color-blue"></i>
                            </a>
                        </li> 
                        <li>
                        <a href="https://yandex.ru/profile/218804584611" target="_blank" data-title="Google" title="" rel="noopener noreferrer">
                                <i className="icon-google-1 icon-color-red"></i>
                            </a>
                        </li>*/}
                    </ul>
                    <p className="copy-right">&copy; { new Date().getFullYear()} Handmade Food. All rights reserved | Design by <a href="http://handmadefood.pro">Handmade Food</a></p>
                </Container>
            </div>

        );
    }
}

export default withTranslation()(Footer);
