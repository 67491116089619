import React, { Component } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';



class Contact extends Component {

    onError(error, sendButton) {
        console.log(error)
        sendButton.value = sendButton.dataset.senterror;
        sendButton.disabled = false;
    }

    onSuccess(sendButton) {
        sendButton.value = sendButton.dataset.sent;
    }

    handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        let sendButton = form.elements.namedItem("send");
        sendButton.value = sendButton.dataset.sending;
        sendButton.disabled = true;

        const data = {
            "access_token": "9b8d79bfbtp4earxeo447i8a",
            "subject": "Handmade Food Site",
            "text": ' Name: ' + form.elements.namedItem("name").value +
                '\n Email: ' + form.elements.namedItem("email").value +
                '\n Phone: ' + form.elements.namedItem("phone").value +
                '\n Message: ' + form.elements.namedItem("msg").value
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        fetch('https://postmail.invotes.com/send', requestOptions)
            .then(async response => {
                if (!response.ok) {
                    this.onError(response.status, sendButton)
                }
                this.onSuccess(sendButton)

            })
            .catch(error => {
                this.onError(error)
                console.error('There was an error!', error);
            });

    };

    render() {

        const { t } = this.props;
        return (
            <>
                <Container>
                    <div className="text-center">
                        <h3 className="tittle wow bounceIn">{t("Contact.Contactus")}</h3>
                        <div className="arrows-two wow zoomIn">
                            <div className="arrows-icon"></div>
                        </div>
                        <p className="wel-text wow fadeInDown">{t("Contact.Contactustext")}</p>
                    </div>
                    <Row>
                        <Col md className="wow fadeInLeft text-center">

                            <Form id="filldetails" className="con-top" onSubmit={this.handleSubmit}>

                                <Form.Group bsPrefix="field" controlId="name" className="field">
                                    <Form.Control bsPrefix="input-label-text" type="text" placeholder={t("Contact.Whoareyou")} required />
                                    <Form.Label bsPrefix="input-label-text">{t("Contact.Name")}</Form.Label>
                                </Form.Group>

                                <Form.Group bsPrefix="field" controlId="email" className="field">
                                    <Form.Control bsPrefix="input-label-text" type="email" placeholder="example@email.com" required />
                                    <Form.Label bsPrefix="input-label-text">{t("Contact.Email")}</Form.Label>
                                </Form.Group>

                                <Form.Group bsPrefix="field" controlId="phone" className="field">
                                    <Form.Control bsPrefix="input-label-text" type="text" placeholder={t("Contact.Phonenumber")} required />
                                    <Form.Label bsPrefix="input-label-text">{t("Contact.Phone")}</Form.Label>
                                </Form.Group>

                                <Form.Group bsPrefix="field" controlId="msg" className="field">
                                    <Form.Control bsPrefix="input-label-text" as="textarea" rows="4" placeholder={t("Contact.Messagepl")} required />
                                    <Form.Label bsPrefix="input-label-text">{t("Contact.Message")}</Form.Label>
                                </Form.Group>

                                <div className="send wow shake">
                                    <input type="submit" value={t("Contact.Send")} data-sending={t("Contact.Sending")} data-sent={t("Contact.Sent")} data-senterror={t("Contact.Senterror")} id="send"></input>
                                </div>
                            </Form>
                        </Col>
                        <Col md className="wow fadeInRight">

                            <div className="con-top user-select">
                                <h4>{t("Contact.Addresses.1.City")}</h4>
                                <ul className="wel-text">
                                    <li><i className="icon-address"></i>{t("Contact.Addresses.1.Name")}</li>
                                    <li>{t("Contact.Addresses.1.Street")}</li>
                                    <li>{t("Contact.Addresses.1.Index")}</li>
                                    <li><i className="icon-call"></i> {t("Contact.Addresses.1.Phone")}</li>
                                    <li><i className="icon-handshake-o"></i>{t("Contact.Addresses.1.Calltimetext")}</li>
                                    <li>{t("Contact.Addresses.1.Calltime")}</li>
                                    <li><i className="icon-gmail icon-color-red"></i><a href="mailto:handmadefood.uz@gmail.com">handmadefood.uz@gmail.com</a></li>
                                    <li><i className="icon-mail"></i><a href="mailto:handmadefood@yandex.ru">handmadefood@yandex.ru</a></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="map wow fadeInUp">
                    <iframe title="This is a unique title" src={t("Contact.Map")}></iframe>
                </div>
            </ >
        );
    }
}

export default withTranslation()(Contact);
