import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './NavMenu.css';
import { WOW } from 'wowjs';

class NavMenuShort extends Component {

    componentDidMount() {
        const wow = new WOW({
            offset: 100,
            mobile: true,
            live: false
        })
        wow.init();
    }

    render() {
        const { t, i18n } = this.props;

        const handleClick = lng => {
            i18n.changeLanguage(lng);
        };       

        return (
            <Navbar fixed="top" expand="lg" bg="dark" variant="dark" collapseOnSelect={true}>
                <Container>
                    <Navbar.Brand href="/" style={{ padding: "0", marginTop: "-0.5rem", marginBottom: "-0.5rem" }}>
                        <div className="icon-font-size">
                            <i className="icon-icon icon-color-orange" style={{ marginRight: "0.5rem" }}></i>
                            <i className="icon-name3 icon-color-white"></i>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <LinkContainer to={'/#home'} exact>
                                <Nav.Link >{t('Navbar.Home')}</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={'/#products'} exact>
                                <Nav.Link >{t('Navbar.Product')}</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={'/#services'} exact>
                                <Nav.Link >{t('Navbar.Wherebuy')}</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={'/#about'} exact>
                                <Nav.Link >{t('Navbar.About')}</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={'/#contact'} exact>
                                <Nav.Link >{t('Navbar.Contact')}</Nav.Link>
                            </LinkContainer>
                            {/* <Nav.Link eventKey="">{t('Navbar.Gallery')}</Nav.Link> */}

                        </Nav>
                        <Nav className="navbar-nav-switch-lan">
                            <Nav.Link eventKey="" onClick={() => handleClick('uz')}>O'Z</Nav.Link>
                            <Nav.Link disabled>&bull;</Nav.Link>
                            <Nav.Link eventKey="" onClick={() => handleClick('ru')}>РУ</Nav.Link>
                            <Nav.Link disabled>&bull;</Nav.Link>
                            <Nav.Link eventKey="" onClick={() => handleClick('en')}>EN</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        );
    }
}

export default withTranslation()(NavMenuShort);





/* <Navbar fixed="top" collapseOnSelect>

          <Navbar.Brand>
            <Link to={'/'}>HF</Link>
          </Navbar.Brand>
          <Navbar.Toggle />

        <Navbar.Collapse>
          <Nav>
            <LinkContainer to={'/'} exact>
              <NavItem>
                 Home
              </NavItem>
            </LinkContainer>

          </Nav>
        </Navbar.Collapse>
      </Navbar>*/