import React from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Details } from './components/Details';
import { NotFoundPage } from './components/NotFoundPage';



function App() {
  
  return (
    <Layout>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/details/' component={Details} />   
        <Route exact path='/details/:key' component={Details} />        
        <Route path="" component={NotFoundPage} />
      </Switch>
    </Layout>
  );
}

export default App;
