import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';


class Page404 extends Component {

    componentDidMount() {
        const elem = document.getElementById("first");
        if (elem) {
            setTimeout(function () {
                elem.scrollIntoView({ block: "start" });
            }, 0);
        }
    }

    render() {

        const { t } = this.props;
        return (
            <>
                <div className="p-1" id="first" ></div>
                <Container className="ab-text position-relative p-5">
                    <Row className="p-5">
                        <Col className="wow slideInDown p-5">
                            <h3 className="tittle wow slideInDown">{t('NotFound')}</h3>
                            <div className="arrows-two wow zoomIn">
                                <div className="arrows-icon"></div>
                            </div>
                            <h4 className="tittle wow slideInLeft">{t('Error')}</h4>                           
                        </Col>
                    </Row>                    
                </Container>
               
            </>
        );
    }
}

export default withTranslation()(Page404);