import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Image, Nav } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import Scrollchor from 'react-scrollchor';
import { LinkContainer } from 'react-router-bootstrap';
import Page404 from './Page404';


class Detail extends Component {

    componentDidMount() {
        const elem = document.getElementById("first");
        if (elem) {
            setTimeout(function () {
                elem.scrollIntoView({ block: "start" });
            }, 0);
        }
    }


    renderDetails(t, trans) {
        const img = require('../assets/' + trans.Img[1]);
        return (
            <>
                <div className="p-1" id="first" ></div>
                <Container className="ab-text position-relative">
                    <Row>
                        <Col className="wow slideInDown">
                            <h3 className={["tittle wow slideInDown", trans.Class].join(' ')}>{trans.Name}</h3>
                            <div className={["arrows-two wow zoomIn", trans.Class].join(' ')}>
                                <div className="arrows-icon"></div>
                            </div>
                            <h4 className="tittle wow slideInLeft">{trans.Type}</h4>
                        </Col>
                    </Row>
                    <Row className="text-left">
                        <Col className="mb-5 pb-5">
                            <p>
                                {ReactHtmlParser(t('Product.' + trans.KeyMethod + 'MethodBefore'))}
                            </p>
                        </Col>
                    </Row>
                    <div className="down down-top-black-2">
                        <Scrollchor to="#method" disableHistory={true}>
                            <i className="icon-angle-down icon-angle-down-center"></i>
                        </Scrollchor>
                    </div>
                </Container>
                <div className={["footer position-relative", trans.Class].join(' ')} id="method">
                    <Container className="ab-text">
                        <Row className="text-left text-light">
                            <Col md={{ span: 8, offset: 3 }}>
                                <strong>{t('Product.Method')}</strong>
                            </Col>
                        </Row>
                        <Row className="text-left text-light">
                            <Col md={{ span: 6, offset: 4 }}>
                                {ReactHtmlParser(trans.Method)}
                            </Col>
                        </Row>
                        <Row className="ab-text">
                            <Col md className="wow slideInLeft">
                                <Image src={img} fluid />
                            </Col>
                            <Col md className="ab-text services-gd text-left wow slideInUp">
                                <h4 className={trans.Class}> {t('Product.Composition')}</h4>
                                <p className={["text-light", trans.Class].join(' ')}>
                                    <strong className="name-company">{t('Product.Stuffing')}</strong>
                                    <span>{trans.Stuffing}</span>
                                </p>
                                <p className={["text-light", trans.Class].join(' ')}>
                                    <strong className="name-company">{t('Product.Dough')}</strong>
                                    <span>{trans.Dough}</span>
                                </p>
                            </Col>
                            <Col md className="ab-text services-gd text-left wow slideInRight">
                                <h4 className={trans.Class}>{ReactHtmlParser(t('Product.FoodValue'))}</h4>
                                <p className="text-light">
                                    <span>{t('Product.Proteins')}</span>
                                    <span className="float-right">{ReactHtmlParser(trans.Proteins)}</span>
                                </p>
                                <p className="text-light">
                                    <span>{t('Product.Fats')}</span>
                                    <span className="float-right">{ReactHtmlParser(trans.Fats)}</span>
                                </p>
                                <p className="text-light">
                                    <span>{t('Product.Carbohydrates')}</span>
                                    <span className="float-right">{ReactHtmlParser(trans.Carbohydrates)}</span>
                                </p>
                                <p className="text-light">
                                    <span>{t('Product.Calorie')}</span>
                                    <span className="float-right">{ReactHtmlParser(trans.Calorie)}</span>
                                </p>
                                <p className="text-light">
                                    <span>{t('Product.Weight')}</span>
                                    <span className="float-right">{ReactHtmlParser(trans.Weight)}</span>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 4, offset: 8 }} className="mb-5 pb-5">
                                <div className="start wow flipInX">
                                    <LinkContainer to={'/#services'}>
                                        <Nav.Link className={["hvr-bounce-to-bottom", trans.Class].join(' ')}>{t('Navbar.Wherebuy')}</Nav.Link>
                                    </LinkContainer>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                    <div className="down">
                        <Scrollchor to="#methodafter" disableHistory={true}>
                            <i className="icon-angle-down icon-angle-down-center"></i>
                        </Scrollchor>
                    </div>
                </div>
                <div id="methodafter" className="position-relative p-1">
                    <Container className="ab-text">
                        <Row className="text-left">
                            <Col className="mb-5 pb-5">
                                <p>
                                    {ReactHtmlParser(t('Product.' + trans.KeyMethod + 'MethodAfter'))}
                                </p>
                            </Col>
                        </Row>

                    </Container>
                    <div className="down down-top-black-2">
                        <Scrollchor to="#footer" disableHistory={true}>
                            <i className="icon-angle-down icon-angle-down-center"></i>
                        </Scrollchor>
                    </div>
                </div>
            </>

        );
    }

    render() {

        const { t } = this.props;
        const trans = t('Products', { returnObjects: true }).filter((e) => e.Key === this.props.data.key);
        return (
            trans.length ?
                this.renderDetails(t, trans[0]) :
                <Page404></Page404>
        );
    }
}

export default withTranslation()(Detail);