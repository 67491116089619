import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Nav, Navbar, Container } from 'react-bootstrap';
import './NavMenu.css';
import { WOW } from 'wowjs';

class NavMenu extends Component {

  componentDidMount() {
    const wow = new WOW({
      offset: 100,
      mobile: true,
      live: false
    })
    wow.init();
    this.handleSelect(window.location.hash.substr(1));
    window.location.hash = "";
  }

  handleSelect(eventKey) {
    if (eventKey) {
      const elem = document.getElementById(eventKey);
      if (elem) {
        setTimeout(function () {
          elem.scrollIntoView({ block: "start", behavior: "smooth" });
        }, 0);
      }
    }
  }

  render() {

    const { t, i18n } = this.props;
    //const w = document.body.clientWidth;
    const handleClick = lng => {
      i18n.changeLanguage(lng);
    };
    return (
      <Navbar fixed="top" onSelect={k => this.handleSelect(k)} collapseOnSelect={true} expand="lg" bg="dark" variant="dark" className="slideInDown wow" >
        <Container>
          <Navbar.Brand href="/" style={{ padding: "0", marginTop: "-0.5rem", marginBottom: "-0.5rem" }}>
            <div className="icon-font-size">
              <i className="icon-icon icon-color-orange" style={{ marginRight: "0.5rem" }}></i>
              <i className="icon-name3 icon-color-white"></i>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link eventKey="home" data-title={t('Navbar.Home')} title=""><i className="icon-home-outline" ></i></Nav.Link>
              <Nav.Link eventKey="products">{t('Navbar.Product')}</Nav.Link>
              <Nav.Link eventKey="services">{t('Navbar.Wherebuy')}</Nav.Link>
              {/* <Nav.Link eventKey="">{t('Navbar.Gallery')}</Nav.Link> */}
              <Nav.Link eventKey="about">{t('Navbar.About')}</Nav.Link>
              <Nav.Link eventKey="contact">{t('Navbar.Contact')}</Nav.Link>
            </Nav>
            <Nav className="navbar-nav-switch-lan">
              <Nav.Link eventKey="" onClick={() => handleClick('uz')}>O'Z</Nav.Link>
              <Nav.Link disabled>&bull;</Nav.Link>
              <Nav.Link eventKey="" onClick={() => handleClick('ru')}>РУ</Nav.Link>
              <Nav.Link disabled>&bull;</Nav.Link>
              <Nav.Link eventKey="" onClick={() => handleClick('en')}>EN</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    );
  }
}

export default withTranslation()(NavMenu);





/* <Navbar fixed="top" collapseOnSelect>

          <Navbar.Brand>
            <Link to={'/'}>HF</Link>
          </Navbar.Brand>
          <Navbar.Toggle />

        <Navbar.Collapse>
          <Nav>
            <LinkContainer to={'/'} exact>
              <NavItem>
                 Home
              </NavItem>
            </LinkContainer>

          </Nav>
        </Navbar.Collapse>
      </Navbar>*/