import React, { Component } from 'react';
import { Row, Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import './Services.css';
import { Service } from './Service';

class Services extends Component {



    render() {
        const { t } = this.props;

        return (
            <Container>
                <h3 className="tittle" style={{ marginBottom: "1rem" }}>{t('Navbar.Wherebuy')}</h3>

                <div className="arrows-two wow zoomIn">
                    <div className="arrows-icon"></div>
                </div>
                <Row className="services-row">
                    {t('Shops', { returnObjects: true }).map((t, index) => {
                        return (
                            <Service key={index} data={{ t: t, i: index }} ></Service>
                        );
                    })
                    }
                </Row>
            </Container>
        );
    }
}

export default withTranslation()(Services);


