import React, { Component } from 'react';
import { Col, Image } from 'react-bootstrap';
import './iconeffects.css';


export class Service extends Component {

    render() {
        const arrEfects = ['slideInLeft', 'slideInDown', 'slideInUp', 'slideInRight'];
        const data = this.props.data;
        const index = data.i - Math.floor(data.i / arrEfects.length) * arrEfects.length;
        return (
            <Col md={3} xs={6} className={["services-gd text-center wow", arrEfects[index]].join(' ')}>
                <div className="hi-icon-wrap hi-icon-effect-9 hi-icon-effect-9a">
                    <span className="hi-icon">
                        <Image src={require('../assets/' + data.t.Img)} fluid />
                    </span>
                </div>
                <h4>{data.t.Name}</h4>
                <p>{data.t.Desc}</p>
                {data.t.YMap ?
                    <a href={data.t.YMap} target="_blank" rel="noopener noreferrer">
                        <div className="wrap-map-loc">
                            <i className="icon-yandex-rect icon-color-red" ></i>
                        </div>
                    </a> :
                    <></>}
                {data.t.GMap ?
                    <a href={data.t.GMap} target="_blank" rel="noopener noreferrer">
                        <div className="wrap-map-loc">
                            <i className="icon-google icon-color-blue" ></i>
                        </div>
                    </a> :
                    <></>}
            </Col>
        );
    }
}