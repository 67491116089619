import React, { Component } from 'react';
import NavMenu from './NavMenu';
import ControlledCarousel from './Carousel';
import Products from './Products';
import Services from './Services';
import About from './About';
import Contact from './Contact';
import Footer from './Footer';
import Scrollchor from 'react-scrollchor';


export class Home extends Component {


    render() {
        
        return (
            <div>
                <NavMenu />
                <div className="carousel-default-wrapper">
                    <ControlledCarousel />
                    <div className="down">
                        <Scrollchor to="#products" disableHistory={true}>
                            <i className="icon-angle-down icon-angle-down-center"></i>
                        </Scrollchor>
                    </div>
                </div>
                <Products />
                <div className="services" id="services" style={{ position: "relative" }}>
                    <div className="down down-top-grey">
                        <Scrollchor to="#services" disableHistory={true}>
                            <i className="icon-angle-down icon-angle-down-center"></i>
                        </Scrollchor>
                    </div>
                    <Services />
                    <div className="down down-top-black">
                        <Scrollchor to="#about" disableHistory={true}>
                            <i className="icon-angle-down icon-angle-down-center"></i>
                        </Scrollchor>
                    </div>
                </div>
                <div className="treat-default-wrapper" id="about">
                    <About />
                    <div className="down">
                        <Scrollchor to="#contact" disableHistory={true}>
                            <i className="icon-angle-down icon-angle-down-center"></i>
                        </Scrollchor>
                    </div>
                </div>
                <div className="section-contact" id="contact">
                    <Contact />
                    <div className="down down-top-black-2">
                        <Scrollchor to="#footer" disableHistory={true}>
                            <i className="icon-angle-down icon-angle-down-center"></i>
                        </Scrollchor>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
