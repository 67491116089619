import React, { Component } from 'react';
import { Row, Col, Nav, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ReactHtmlParser from 'react-html-parser';

export class Product extends Component {

    Col5(t, readmore) {
        return (
            <Col md={5} className="ab-text">
                <h3 className={["tittle wow slideInDown", t.Class].join(' ')}>{t.Name}</h3>
                <h4 className="tittle wow slideInLeft">{t.Type}</h4>
                <div className={["arrows-two wow zoomIn", t.Class].join(' ')}>
                    <div className="arrows-icon"></div>
                </div>
                <p className="wow slideInUp">{t.Desc}</p>
                <div className="start wow flipInX">
                    <LinkContainer to={'/details/:' + t.Key}>
                        <Nav.Link className={["hvr-bounce-to-bottom", t.Class].join(' ')}>{readmore}</Nav.Link>
                    </LinkContainer>
                </div>
            </Col>
        );
    }

    Col7(t) {
        return (
            <Col md={7}>
                <Row className="grid" noGutters={true}>
                    <Col xs className="wow slideInRight">
                        <figure className="effect-jazz">
                            <Image src={require('../assets/' + t.Img[1])} fluid />
                            <figcaption>
                                <h4 className={t.Class}>Handmade <span>Food</span></h4>
                                <p>{ReactHtmlParser(t.SmallDesc1)}</p>
                            </figcaption>
                        </figure>
                    </Col>
                    <Col xs className="wow slideInRight">
                        <figure className="effect-jazz">
                            <Image src={require('../assets/' + t.Img[2])} fluid />
                            <figcaption>
                                <h4 className={t.Class}>Handmade <span>Food</span></h4>
                                <p>{ReactHtmlParser(t.SmallDesc2)}</p>
                            </figcaption>
                        </figure>
                    </Col>
                </Row>
            </Col>
        );
    }

    ProductLeft(t, readmore) {
        return (
            <Row className="flex-column-reverse flex-md-row">
                {this.Col5(t, readmore)}
                {this.Col7(t)}
            </Row>
        );
    }

    ProductRight(t, readmore) {
        return (
            <Row>
                {this.Col7(t)}
                {this.Col5(t, readmore)}
            </Row>
        );
    }


    render() {
        const data = this.props.data;

        return (
            data.i % 2 === 0 ?
                this.ProductLeft(data.t, data.readmore) :
                this.ProductRight(data.t, data.readmore)
        );
    }
}