import './iconstyle.css';
import 'bootstrap/dist/css/bootstrap.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import './components/i18n';
//import { CookiesProvider } from 'react-cookie';
import * as serviceWorker from './serviceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');



ReactDOM.render(
  <Suspense fallback={(<div className="load-wrap"><div className="logo-load"></div><div className="logo"></div></div>)}>
    <BrowserRouter basename={baseUrl}>
      {/* <CookiesProvider> */}
        <App />
      {/* </CookiesProvider> */}
    </BrowserRouter>
  </Suspense>
  , rootElement);

serviceWorker.unregister();