import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

class About extends Component {

    render() {
        const { t } = this.props;
        return (
            <div className="treat-default">
                <Container className="bottom-top">
                    <h3 className="wow slideInDown">{t("About.Ourinfo")}</h3>
                    <div>
                        <div className="arrows">
                            <div className="arrows-icon"></div>
                        </div>
                    </div>
                    <p className="wow slideInLeft">
                        {ReactHtmlParser(t('About.Desc'))}
                    </p>
                    
                   
                    <h3 style={{padding: "2rem"}}><i className="icon-icon icon-color-white"></i></h3> 
                    <h3 className=" wow slideInDown">{t('About.h3')}</h3>
                    <span>{t('About.span')}</span>
                    <div>
                        <div className="arrows">
                            <div className="arrows-icon"></div>
                        </div>
                    </div>
                    <p className="wow slideInRight">{t('About.p')}</p>
                </Container>
            </div>

        );
    }
}

export default withTranslation()(About);
